import 'react';
import * as React from 'react';
import Card from 'reactstrap/lib/Card';
import CardBody from 'reactstrap/lib/CardBody';
import Form from 'reactstrap/lib/Form';
import Input from 'reactstrap/lib/Input';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import { FormEvent } from 'react';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../store';
import selectors from '../store/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import {
    isAuthenticatedAction,
    loginAction,
    resetActionStatusAction,
    setPayLinkCustomerAction,
} from '../store/actions';
import { AuthenticationMethod, CustomerModel, addCustomer, ResponseError, PayLinkModel } from '../apis';
import Spinner from 'reactstrap/lib/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROUTE } from '../config';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import VerificationCodeModal from '../components/VerificationCodeModal';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import FormGroup from 'reactstrap/lib/FormGroup';
import * as querystring from 'querystring';
import Layout from '../components/Layout';
import PhoneNumberInput from '../components/PhoneNumberInput';
import FormFeedback from '../components/FormFeedback';
import Label from 'reactstrap/lib/Label';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import MerchantImg from '../components/MerchantImg';

interface LoginParams {
    mobile: string;
    email: string;
}

interface StateProps {
    isRequesting: boolean;
    error: ResponseError | null;
    merchantDisplayName: string;
    customer: CustomerModel | null;
    link: PayLinkModel | null;
}

type OwnProps = { navToSignup?: () => void } & RouteComponentProps;

interface DispatchProps {
    login: typeof loginAction;
    isAuthenticatedAction: typeof isAuthenticatedAction;
    resetActionStatusAction: () => void;
    linkCustomer: typeof setPayLinkCustomerAction;
}

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{ code: string }>;

interface State {
    authenticationMethod: AuthenticationMethod;
    mobile: string;
    email: string;
    showModal: boolean;
    code?: string;
}

class LoginPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            authenticationMethod: 'sms',
            mobile: '',
            email: '',
            showModal: false,
            code: this.props.match.params.code,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const params = querystring.parse(this.props.location.search.slice(1));
        const paramsObject = params as Partial<LoginParams>;
        this.setState({
            mobile: paramsObject.mobile || '',
            email: paramsObject.email || '',
        });
    }

    username = (authenticationMethod?: AuthenticationMethod) => {
        return (authenticationMethod || this.state.authenticationMethod) === 'email'
            ? this.state.email
            : this.state.mobile;
    };

    onLogin = async (authenticationMethod: AuthenticationMethod) => {
        try {
            this.setState({ authenticationMethod });
            await this.props.login(
                authenticationMethod,
                this.username(authenticationMethod),
                this.props.link?.merchant.short_name,
            );
            this.setState({ showModal: true });
        } catch {
            // Error
        }
    };

    onSubmit = (authenticationMethod: AuthenticationMethod) => async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.onLogin(authenticationMethod);
    };

    onResend = () => this.onLogin(this.state.authenticationMethod);

    onSuccess = async () => {
        this.props.isAuthenticatedAction(true);
        if (this.state.code && this.props.customer?.id) {
            await addCustomer(this.state.code, this.props.customer.id);
            window.location.reload();
        } else {
            this.props.history.push(ROUTE.HOME);
        }
    };

    toggle = () => {
        this.setState({ showModal: !this.state.showModal });
    };

    isInvalid = (method: AuthenticationMethod) => {
        return (
            this.state.authenticationMethod === method && selectors.global.isPropertyError('username', this.props.error)
        );
    };

    render() {
        const { isRequesting, error, merchantDisplayName } = this.props;
        const { authenticationMethod, mobile, email, showModal } = this.state;
        return (
            <Layout navbar={false} centered>
                <Card className="text-center shadow-lg mw-sm">
                    <CardBody className="p-md-5">
                        <div className="h4">
                            <strong>{merchantDisplayName}</strong> Customer Payments
                        </div>
                        <MerchantImg />
                        <div className="lead">To log in, please enter your mobile phone number or email address.</div>
                    </CardBody>
                    <CardBody className="p-md-5 border-top">
                        <Label>Mobile</Label>
                        <Form id="mobile-form" onSubmit={this.onSubmit('sms')} className="d-flex align-items-baseline">
                            <FormGroup className="flex-fill mb-0">
                                <PhoneNumberInput
                                    value={mobile}
                                    isInvalid={this.isInvalid('sms')}
                                    onChange={(mobile: string) => this.setState({ mobile })}
                                    disabled={isRequesting}
                                />
                                <FormFeedback error={error} data={{ identifier: 'mobile' }} />
                            </FormGroup>
                            <Button disabled={isRequesting} color={'primary'} className="ml-3">
                                {isRequesting && authenticationMethod === 'sms' ? (
                                    <Spinner type="grow" color="white" size={'sm'} />
                                ) : (
                                    <FontAwesomeIcon icon={faMobileAlt} fixedWidth />
                                )}
                            </Button>
                        </Form>
                        <Row className="my-3 d-flex">
                            <Col>
                                <hr />
                            </Col>
                            <Col className="d-flex align-items-center justify-content-center">
                                <div>Or</div>
                            </Col>
                            <Col>
                                <hr />
                            </Col>
                        </Row>
                        <Label>Email</Label>
                        <Form id="email-form" onSubmit={this.onSubmit('email')} className="d-flex align-items-baseline">
                            <FormGroup className="flex-fill mb-0">
                                <Input
                                    type="email"
                                    placeholder="Your Email address"
                                    autoComplete="email"
                                    disabled={isRequesting}
                                    value={email}
                                    onChange={(e) => {
                                        this.props.resetActionStatusAction();
                                        this.setState({
                                            email: e.currentTarget.value,
                                        });
                                    }}
                                    invalid={this.isInvalid('email')}
                                />
                                <FormFeedback error={error} data={{ identifier: 'email' }} />
                            </FormGroup>
                            <Button disabled={isRequesting} color={'primary'} className="ml-3">
                                {isRequesting && authenticationMethod === 'email' ? (
                                    <Spinner type="grow" color="white" size={'sm'} />
                                ) : (
                                    <FontAwesomeIcon icon={faAt} fixedWidth />
                                )}
                            </Button>
                        </Form>
                        <VerificationCodeModal
                            show={showModal}
                            value={this.username()}
                            onClose={this.toggle}
                            onResend={this.onResend}
                            isResending={isRequesting}
                            onSuccess={this.onSuccess}
                            authenticationMethod={authenticationMethod}
                        />
                    </CardBody>
                    <CardBody className="border-top">
                        Don&apos;t have an account?
                        {this.props.navToSignup ? (
                            <button
                                onClick={this.props.navToSignup}
                                style={{
                                    background: 'none',
                                    border: 'none',
                                    color: 'blue',
                                    cursor: 'pointer',
                                    fontSize: 'inherit',
                                    padding: 5,
                                }}
                            >
                                Sign Up
                            </button>
                        ) : (
                            <Link to={ROUTE.SIGN_UP} style={{ padding: 5 }}>
                                Sign Up
                            </Link>
                        )}
                    </CardBody>
                </Card>
            </Layout>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, OwnProps, ApplicationState> = (state) => ({
    error: selectors.global.getResponseError(state.global.actions, 'login'),
    isRequesting: selectors.global.isActionRequesting(state.global.actions, 'login'),
    merchantDisplayName: state.global.merchant.display_name,
    customer: state.authentication.customer,
    link: state.payLink.data ? state.payLink.data : null,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            login: loginAction,
            isAuthenticatedAction: isAuthenticatedAction,
            resetActionStatusAction: () => dispatch(resetActionStatusAction('login')),
            linkCustomer: setPayLinkCustomerAction,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
