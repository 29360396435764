import * as React from 'react';
import NumberInput from './NumberInput';
import { Button } from 'reactstrap';
import selectors from '../store/selectors';
import { TipCustomizationModel, TipType } from '../store/types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

interface Props {
    originalAmount: number;
    tipModel: TipCustomizationModel;
    amount: number;
    setDefaultValueOnChange?: boolean;
    onSetAmount: (value: number, error: boolean) => void;
}

interface State {
    showAmountField: boolean;
    errorMessage: string | null;
    tips: number[];
    tipType: TipType;
}

export default class TipSelection extends React.Component<Props, State> {
    private tipInput: HTMLInputElement | null = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            showAmountField: false,
            errorMessage: null,
            tips: [],
            tipType: 'fixed',
        };
    }

    async componentDidMount() {
        const tips = [this.props.tipModel.value1, this.props.tipModel.value2, this.props.tipModel.value3];
        const isPercentage = this.props.tipModel.type === 'percentage';
        // tips less than $1 throw an error at checkout, an issue when using type is percentage
        // default to [1,2,3]
        const tipAmount1 = selectors.number.getTipAmount(
            this.props.tipModel.type,
            this.props.tipModel.value1,
            this.props.originalAmount,
        );
        if (isPercentage && tipAmount1 < 100) {
            await this.setState({ tips: [100, 200, 300], tipType: 'fixed' });
        } else {
            await this.setState({ tips, tipType: this.props.tipModel.type });
        }
    }

    selectTip = (tipValue: number): void => {
        const amount = this.getAmount(tipValue);
        this.onSetAmount(this.props.amount === amount ? 0 : amount);
    };

    toggleAmountField = async (): Promise<void> => {
        const { tips, tipType } = this.state;
        const tipAmounts = tips.map((tip) => selectors.number.getTipAmount(tipType, tip, this.props.originalAmount));
        let tipAmount = this.props.amount;
        // If we show the amount, we set to zero
        if (this.props.amount === 0) {
            this.props.onSetAmount(0, false);
        } else {
            if (this.state.showAmountField) {
                // If tip amount does not match a default value, we set the second as a default
                if (!tipAmounts.includes(this.props.amount)) {
                    tipAmount = this.props.setDefaultValueOnChange ? tipAmounts[1] : 0;
                }
            }
            this.props.onSetAmount(tipAmount, false);
        }

        await this.setState({
            showAmountField: !this.state.showAmountField,
            errorMessage: null,
        });

        if (this.tipInput && this.state.showAmountField) {
            this.tipInput.focus();
        }
    };

    onSetAmount = (tip: number): void => {
        let errorMessage: string | null = null;
        // We don't use custom percent amounts, it will always be fixed
        if (this.state.showAmountField) {
            if (tip === 0) {
                // Not an error
            } else if (tip < 100) {
                errorMessage = 'Minimum tip amount is $1.00';
            } else if (tip >= 10000) {
                errorMessage = 'Maximum tip amount is $100';
            }
        }

        this.setState({ errorMessage }, () => this.props.onSetAmount(tip, !!errorMessage));
    };

    getAmount = (value: number): number => {
        const { originalAmount } = this.props;
        const { tipType } = this.state;
        return selectors.number.getTipAmount(tipType, value, originalAmount);
    };

    render() {
        const { amount } = this.props;
        const { tips, tipType } = this.state;

        const renderTipButton = (tip: number) => {
            const isPercent = tipType === 'percentage';
            const tipSubValue = isPercent
                ? selectors.number.amountToString(
                      selectors.number.getTipAmount(tipType, tip, this.props.originalAmount),
                  )
                : null;

            const tipValue = isPercent
                ? `${Math.round(tip / 100)}%`
                : selectors.number.amountToString(
                      selectors.number.getTipAmount(tipType, tip, this.props.originalAmount),
                  );

            return (
                <Col className="px-2">
                    <Button
                        onClick={() => this.selectTip(tip)}
                        outline={amount !== tip}
                        active={amount === this.getAmount(tip)}
                        size="lg"
                        className={'btn-tip'}
                    >
                        {tipValue}
                        {/* only show for percentage tips */}
                        {tipSubValue && <div className="dollar-amount">{tipSubValue}</div>}
                    </Button>
                </Col>
            );
        };

        return (
            <div className="text-center" data-sh="tip-selection">
                <div className="d-flex justify-content-center align-items-center mb-4" data-sh="tip-value">
                    {this.state.showAmountField ? (
                        <div className="d-block">
                            <NumberInput
                                innerRef={(input) => {
                                    this.tipInput = input as HTMLInputElement;
                                }}
                                className="mx-auto form-control-tip active"
                                onValueChange={this.onSetAmount}
                                max={100000}
                                value={this.props.amount}
                                prefix={'$'}
                                digits={2}
                            />
                            {this.state.errorMessage ? (
                                <div className="mt-3 font-italic text-danger">{this.state.errorMessage}</div>
                            ) : null}
                        </div>
                    ) : (
                        <Row className="justify-content-center">{tips.map((tip) => renderTipButton(tip))}</Row>
                    )}
                </div>
                <Button className="btn-custom-tip" onClick={this.toggleAmountField} data-sh="tip-custom-button">
                    {!this.state.showAmountField ? 'Enter Custom Amount' : 'Use Predefined Amount'}
                </Button>
            </div>
        );
    }
}
