import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { PortalResponse, PortalResponseFailed, ResponseError, ResponseErrorCode, ResponseErrorType } from './response';
import { merge } from 'lodash';

export const segmentListLimit = 20;

export type RequestConfig = AxiosRequestConfig;

export async function request<T>(config: RequestConfig): Promise<PortalResponse<T>> {
    const token = localStorage.getItem('token');
    if (token) {
        config = merge(config, {
            headers: { Authorization: `Bearer ${token}` },
        });
    }

    try {
        const res = await axios.request(config);
        return res.data;
    } catch (e) {
        const error = e as AxiosError<PortalResponseFailed>;
        const errorData = error && error.response && error.response.data.error;
        if (!errorData)
            throw new ResponseError(
                ResponseErrorType.Unknown,
                ResponseErrorCode.Unknown,
                null,
                'Something went wrong.',
            );
        throw new ResponseError(
            errorData.type as ResponseErrorType,
            errorData.code as ResponseErrorCode,
            errorData.property,
            errorData.message ?? '',
        );
    }
}
