import { ActionName, ActionStatus, GlobalState, SPLIT_FLAG } from '../types';
import { ResponseError, ResponseErrorCode } from '../../apis';
import { ISplitTreatments } from '@splitsoftware/splitio-redux/lib/types';

export function isActionRequesting(requests: ActionStatus[], name: ActionName): boolean {
    const status = requests.find((el) => el.name === name);
    if (!status) return false;
    return status.isRequesting;
}

export function getActionStatus(statuses: ActionStatus[], name: ActionName) {
    return statuses.find((el) => el.name === name);
}

export function getResponseError(statuses: ActionStatus[], name: ActionName) {
    const status = getActionStatus(statuses, name);
    if (!status) return null;
    return status.responseError || null;
}

export function isPropertyError(propertyName: string | null, error: ResponseError | null) {
    return (error && error.property === propertyName) || false;
}

export interface GetErrorMessageOptions {
    transform?: (responseErrorCode: ResponseErrorCode) => string | undefined;
}

export function getErrorMessage(error: ResponseError | null, options: GetErrorMessageOptions = {}) {
    if (!error) return null;

    if (options.transform) {
        const result = options.transform(error.code);
        if (result) {
            return result;
        }
    }

    switch (error.code) {
        case ResponseErrorCode.InvalidField:
            return error.message == null || error.message.length == 0 ? 'Value is not valid.' : error.message;
        case ResponseErrorCode.MissingField:
            return 'Value is required.';
        case ResponseErrorCode.ValueTaken:
            return 'Value is already taken.';
        default:
            return 'Something went wrong. Please try again.';
    }
}

export function getErrorCode(error: ResponseError | null) {
    return error && error.code;
}

export const getActionError = (name: ActionName, actions: ActionStatus[]) => {
    const action = getActionStatus(actions, name);
    return action ? action.responseError : undefined;
};

export function getTitle(state: GlobalState): string {
    let result = (state.merchant && state.merchant.display_name) || 'Stronghold';
    const headerTitle = state.headerTitle;
    if (headerTitle.values.length > 0) {
        result = [...headerTitle.values, result].join(` ${headerTitle.separator} `);
    }
    return result;
}

export function getPortalLink(merchantShortName: string, origin: string): string {
    origin = origin.replace('strongholdpay', 'onstrongholdpay');
    origin = origin.replace('://sandbox', `://${merchantShortName}-sandbox`);
    origin = origin.replace('://development', `://${merchantShortName}-development`);
    origin = origin.replace('://staging', `://${merchantShortName}-staging`);
    origin = origin.replace('://onstrongholdpay', `://${merchantShortName}.onstrongholdpay`);

    return origin;
}

export function isMerchantRoute() {
    const regex = /\/\/(sandbox)?((-)?staging|(-)?development)?(\.)?((on)?strongholdpay)/gm;

    return !window.location.origin.match(regex);
}

/**
 * This function set the default value for the flag
 * TODO: See if the return type is something else than a boolean, we could type the flags directly
 * @param name
 * @param flags
 */
export function getSplitFlagValue(name: SPLIT_FLAG, flags: ISplitTreatments): boolean {
    flags = flags || {};
    const formattedFlag = flags[SPLIT_FLAG.SIMPLE_CHECKOUT];
    const treatment = Object.values(formattedFlag)[0].treatment;
    switch (name) {
        case SPLIT_FLAG.SIMPLE_CHECKOUT:
            return treatment === 'on' ? true : false;
        default:
            throw new Error('Not implemented flag value');
    }
}
